import React from 'react';
import { connect } from 'react-redux';
import { Button, SearchInput, DataTable, Pager } from 'tyb';
import { formatDate } from 'ucode-utils';
import enums from '@/assets/enum.json';
const { customFields, customFieldsStatus } = iceStarkData.store.get('commonAction')?.customFields;


import './index.scss';

@connect(
    state => ({
        permissionIds: state.users.permissionIds || [],
        loading: state.customFields.loading,
        customFields: state.customFields.customFields,
        customFieldsStatusLoaded: state.customFields.customFieldsStatusLoaded,
    }),
    {
        getCustomFields: customFields.REQUEST,
        setCustomFieldsStatus: customFieldsStatus.REQUEST,
    })
export default class CustomFormat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.params = { page: 0, size: 20 };
        this.status = { ENABLE: '禁用', DISABLE: '启用' };
        this.columns = [
            {
                key: 'id',
                dataIndex: 'id',
                title: '序号',
            },
            {
                key: 'fieldName',
                dataIndex: 'fieldName',
                title: '字段名称',
            },
            {
                key: 'createdTime',
                dataIndex: 'createdTime',
                title: '创建时间',
                render: (val) => <div>{formatDate(val)}</div>
            },
            {
                key: 'mode',
                dataIndex: 'mode',
                title: '模式',
                render: (val) => <div>{enums.modeSelection[val] ? enums.modeSelection[val].name : ''}</div>
            },
            {
                key: 'operate',
                dataIndex: 'operate',
                title: '操作',
                render: (val, record) => (
                    <div className="operate">
                        {this.props.permissionIds.includes('dm.customFields.view') && <a href="javascript:;" onClick={() => { this.handleVisible(record.id) }}>查看</a>}
                        {this.props.permissionIds.includes('dm.customFields.edit') && <a href="javascript:;" onClick={() => { this.handleOperate(record) }}>{this.status[record.status]}</a>}
                        {/* {(this.props.permissionIds.includes('dm.customFields.view') && !this.props.permissionIds.includes('dm.customFields.edit')) && <a href="javascript:;" onClick={() => { this.handleVisible(record.id) }}>查看</a>}
                        {this.props.permissionIds.includes('dm.customFields.edit') && <a href="javascript:;" onClick={() => { this.handleVisible(record.id) }}>编辑</a>}
                        {this.props.permissionIds.includes('dm.customFields.edit') && <a href="javascript:;" onClick={() => { this.handleOperate(record) }}>{this.status[record.status]}</a>} */}
                    </div>)
            },
        ]
    }

    componentDidMount() {
        this.props.getCustomFields(this.params);
    }


    componentDidUpdate(preProps) {
        if ((/2\d{2}$/.test(this.props.customFieldsStatusLoaded) && this.props.customFieldsStatusLoaded !== preProps.customFieldsStatusLoaded)) {
            this.props.getCustomFields(this.params);
        }
    }

    search = () => {
        this.setState({
            page: 0,
        });
        this.params.page = 0;
        this.props.getCustomFields(this.params);
    }

    onPageChange = (val) => {
        this.setState({ page: val });
        this.params.page = val;
        this.props.getCustomFields(this.params);
    }

    onPerPageChange = (val) => {
        this.setState({ perPageCount: val });
        this.params.size = val;
        this.params.page = 0;
        this.props.getCustomFields(this.params);
    }

    handleVisible = (id) => {
        this.props.history.push(`/codecustomFields/view?id=${id}`);
    }

    handleOperate = (record) => {
        this.props.setCustomFieldsStatus({ id: record.id, status: record.status == 'ENABLE' ? 'DISABLE' : 'ENABLE' });
    }


    render() {
        const { keywords, page, perPageCount = 20 } = this.state;
        const { permissionIds, loading, customFields = {} } = this.props;
        const { content, totalElements } = customFields;
        return (
            <React.Fragment>
                <div className="">
                    <div className="search-bar">
                        {permissionIds.includes('dm.customFields.add') && <Button onClick={() => { this.props.history.push('/codecustomFields/view') }}>创建</Button>}
                        <div className="search-input-block">
                            <SearchInput
                                onChange={(val) => { this.params.keywords = val; this.setState({ keywords: val }) }}
                                onSearch={this.search}
                                value={keywords}
                                placeholder="请输入字段名称关键字"
                            />
                        </div>
                    </div>


                    <DataTable
                        columns={this.columns}
                        data={content}
                        rowKey="id"
                        loading={loading}
                        rowClassName={(record) => record.status == 'DISABLE' ? 'table-gray-row' : ''}
                    />
                    {content && content.length > 0 && <div className="page-padding20"> <Pager
                        total={totalElements}
                        current={page}
                        perPageCountList={[10, 20, 50]}
                        perPageCount={perPageCount}
                        onPageChange={this.onPageChange}
                        onPerPageChange={this.onPerPageChange}
                    />
                    </div>}

                </div>
            </React.Fragment>
        )
    }
}



